<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="login">
    <img src="/img/icon.png" />
    <h2 class="title">{{ $t("loginTitle") }}</h2>
    <div class="one">
      <div class="div">
        <v-text-field
          type="text"
          class="input"
          :placeholder="$t('loginUserPlaceholder')"
          prepend-inner-icon="mdi-account"
          v-model="email"
          :rules="userRules"
          v-on:keyup.enter="login"
          solo
        >
        </v-text-field>
      </div>
    </div>
    <div class="pass">
      <div class="div">
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          class="input"
          :placeholder="$t('loginPasswordPlaceholder')"
          prepend-inner-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showPassword = !showPassword)"
          v-model="password"
          :rules="passwordRules"
          v-on:keyup.enter="login"
          solo
        >
        </v-text-field>
      </div>
    </div>
    <router-link to="/auth/recover" class="forgot link">
      {{ $t("loginForgotPasswordTitle") }}
    </router-link>

    <v-btn depressed class="btn-confirm btn" @click="login" :disabled="loading">
      <v-progress-circular
        color="white"
        indeterminate
        v-if="loading"
      ></v-progress-circular>
      <div v-else>{{ $t("loginButton") }}</div>
    </v-btn>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> Confirmar cuenta </v-card-title>
          <v-card-text>
            <v-text-field
              type="text"
              class="input"
              placeholder="Código"
              prepend-inner-icon="mdi-shield-lock"
              v-model="code"
            >
            </v-text-field>
            <p>
              ¿No recibiste el código?
              <a @click="resendCode" class="link">Reenviar</a>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn color="green darken-1" text @click="confirmAccount">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import { Login, ConfirmUser, ResendCode, getMessage } from "@/core/auth/";

export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      dialog: false,
      code: "",
      userRules: [
        (value) => !!value || this.$t("loginRequiredUsername"),
        // (value) =>
        //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
        //   "Ingrese un correo valido",
      ],
      passwordRules: [(value) => !!value || this.$t("loginRequiredPassword")],
      valid: false,
      loading: false,
    };
  },
  methods: {
    async login() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      try {
        const user = await Login(this.email, this.password);
        if (user.newPassword) {
          this.$router.push({
            name: "ChangePassword",
            params: {
              cognitoUser: user.cognitoUser,
              userAttributes: user.userAttributes,
            },
          });
        } else {
          this.$store.dispatch("config/login", user, this.$apollo);
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        this.$toast.error(getMessage(error), {
          position: "top-right",
        });
      }
      this.loading = false;
    },
    resendCode() {
      ResendCode(this.email);
    },
    async confirmAccount() {
      try {
        await ConfirmUser({ user: this.email, code: this.code });
        this.dialog = false;
        this.login();
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top-right",
        });
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/login.css"></style>
